<template>
  <v-card :loading="loading" dense outlined class="pb-1 pl-2 pr-2 mt-2">
    <v-row>
      <v-col
        v-for="(lineSelected, indexRevision) in revisionContent"
        :key="indexRevision"
        sm="6"
      >
        <v-card outlined class="mx-auto" :loading="loading">
          <v-card-title class="primary white--text">
            <div class="headerClass">
              {{lineSelected.ddi+' ('+lineSelected.ddd+') '+lineSelected.nr_linha }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <LineComponent
                  :selectedLine="lineSelected"
                  :readonly="true"
                ></LineComponent>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LineComponent from "@/components/forms/LineComponent.vue";

export default {
  components: { LineComponent },
  props: ["revision"],
  data() {
    return {
      revisionContent: [],
      loading: true,
      loading2: true
    };
  },

  async mounted() {
    if (this.revision["cancel_line"]) {
      this.revisionContent = this.revision["cancel_line"];
    } else if (this.revision["suspend_line"]) {
      this.revisionContent = this.revision["suspend_line"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"])
  },
  methods: {
    ...mapActions("packages", ["loadLinePackages"])
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
