<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{ dynamicLabel }}</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      sm="6"
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{lineSelected && lineSelected.ddi ? lineSelected.ddi+' ('+lineSelected.ddd+') '+lineSelected.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <LineComponent
                :rules="[v => (v && Object.keys(v).length > 0) || 'Obrigatório']"
                :selectedLine="lineSelected"
                @save="
                  lineSelected = $event;
                  updateSelectedLine($event, index);
                "
              ></LineComponent>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import LineComponent from "@/components/forms/LineComponent.vue";
import isEmpty from "lodash/isEmpty";
export default {
  components: { LineComponent },
  props: {
    index: {},
    uniqueKeyStorage: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lineSelect: [],
      loading: true,
      show: false,
      dynamicLabel: "",
      resultStorage: null,
      lineRules: [v => !!v || "Linha é obrigatório"],
    };
  },
  async mounted() {
    if (this.wizard.model["suspend_line"] || this.wizard.model["cancel_line"]) {
      this.wizard.forms.map(form => {
        if (form.type == "suspend_line" || form.type == "cancel_line")
          this.dynamicLabel = form.label;
      });
    }

    this.lineSelect = [...this.lineSelect, {}];

    const dataLocalStorage = await this.loadDataLocalStorage(this.uniqueKeyStorage);
    if (!isEmpty(dataLocalStorage) && this.page === 'wizard') {
      this.resultStorage = dataLocalStorage;
      this.lineSelect = Object.assign([], this.resultStorage);
    }

    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),

    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLinePackages"]),
    ...mapActions("solicitation", ["loadDataLocalStorage"]),
    ...mapMutations({
      setModel: "forms/setModelCancelLine"
    }),
    async clickAddNewLine() {
      this.lineSelect = [...this.lineSelect, {}];
    },
    async updateSelectedLine(lineSelected, index) {
      this.lineSelect[index] = lineSelected;
      this.updateValues(this.lineSelect);
    },
    updateValues(cancelLineList) {
      const payload = {
        step: this.index,
        selected: cancelLineList
      };
      this.setModel(payload);
      if (this.page === 'wizard') {
        localStorage.setItem(this.uniqueKeyStorage, JSON.stringify(cancelLineList));
      }
    },
    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
      }
      this.updateSelectedLine();
    },
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
